/* eslint no-param-reassign: ["error", { "props": false }] */
import { Storage } from '@capacitor/storage';
import Store from '.';

export const persistMenuOpen = async (value) => {
  await Storage.set({
    key: 'menuOpen',
    value: JSON.stringify(value),
  });
};

export const setMenuOpen = (open) => {
  Store.update((s) => {
    s.menuOpen = open;
  });
};

export const setNotificationsOpen = (open) => {
  Store.update((s) => {
    s.notificationsOpen = open;
  });
};

export const persistNotificationOpen = async (value) => {
  await Storage.set({
    key: 'notificationsOpen',
    value: JSON.stringify(value),
  });
};

export const setSettings = (settings) => {
  Store.update((s) => {
    s.settings = settings;
  });
};

export const persistSettings = async (value) => {
  await Storage.set({
    key: 'settings',
    value: JSON.stringify(value),
  });
};

// App-specific actions

export const setDone = (list, item, done) => {
  Store.update((s, o) => {
    const listIndex = o.lists.findIndex((l) => l === list);
    const itemIndex = o.lists[listIndex].items.findIndex((i) => i === item);
    const ns = { ...s };
    ns.lists[listIndex].items[itemIndex].done = done;
    if (list === o.selectedList) {
      ns.selectedList = s.lists[listIndex];
    }
    return ns;
  });
};

export const persistDone = async (value) => {
  await Storage.set({
    key: 'selectedList',
    value: JSON.stringify(value),
  });
};

export const persistUser = async (value) => {
  await Storage.set({
    key: 'user',
    value: JSON.stringify(value),
  });
};

export const setUser = (user) => {
  Store.update((s) => {
    s.user = user;
  });
  persistUser(user);
};

export const persistMessages = async (value) => {
  await Storage.set({
    key: 'messages',
    value: JSON.stringify(value),
  });
};

export const setMessages = (messages) => {
  Store.update((s) => {
    s.messages = messages;
  });
  persistMessages(messages);
};

export const persistToken = async (token) => {
  await Storage.set({
    key: 'token',
    value: token,
  });
};

export const setToken = (token) => {
  Store.update((s) => {
    s.token = token;
  });
  persistToken(token);
};
