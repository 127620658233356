/* eslint-disable */
import Head from 'next/head';
import Script from 'next/script';
import getConfig from 'next/config';

// Ionic Styles
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

// Global Styles
import '@/shared/styles/global.css';
import '@/shared/styles/variables.css';

// Vendor Styles
import '@/shared/styles/vendor/react-toggle.css';
import '@/shared/styles/vendor/ionicons.css';

// Tailwind Styles
import 'tailwindcss/tailwind.css';

import { NotificationContainer } from 'react-notifications';

import { useEffect } from 'react';
import { Storage } from '@capacitor/storage';
import Store from '@/shared/store';
import * as selectors from '@/shared/store/selectors';
import { setToken, setUser } from '@/shared/store/actions';
import axios from 'axios';
import { setupIonicReact } from '@ionic/react';

function App({ Component, pageProps }) {
  const { publicRuntimeConfig } = getConfig();
  const user = Store.useState(selectors.getUser);
  const token = Store.useState(selectors.getToken);

  useEffect(() => {
    setupIonicReact({
      mode: `md`,
    });
    async function updateAuth(user, token) {
      if (!user || JSON.stringify(user) === '{}' || user === null) {
        const persistUser = await Storage.get({ key: 'user' });
        setUser(JSON.parse(persistUser.value));
      }

      if (!token) {
        const persistToken = await Storage.get({ key: 'token' });
        setToken(persistToken.value);
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${persistToken.value}`;
      }
    }
    updateAuth(user, token);
  }, [user, token]);

  return (
    <>
      <Head>
        <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="LP Calculator" />
        <title>LP Calculator</title>
        <meta
          name="description"
          content="Free loan calculator to determine repayment plan, interest cost, and amortization schedule of conventional amortized loans, deferred payment loans, and bonds. Also, learn more about different types of loans, experiment with other loan calculators, or explore other calculators addressing finance, math, fitness, health, and many more."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#008383" />
        <link rel="apple-touch-icon" href="/icons/apple/apple-touch-icon.png" />
        <link rel="shortcut icon" href="/icons/favicon.ico" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={publicRuntimeConfig.base_url} />
        <meta name="twitter:title" content="Lets Plan" />
        <meta
          name="twitter:description"
          content="The best loan estimate calculator in the market"
        />
        <meta
          name="twitter:image"
          content={`${publicRuntimeConfig.base_url}/icons/android-chrome-192x192.png`}
        />
        <meta name="twitter:creator" content="@voicingewe" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Lets Plan" />
        <meta
          property="og:description"
          content="The best loan estimate calculator in the market"
        />
        <meta property="og:site_name" content="Lets Plan" />
        <meta property="og:url" content={publicRuntimeConfig.base_url} />
        <meta
          property="og:image"
          content={`${publicRuntimeConfig.base_url}/icons/apple-touch-icon.png`}
        />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <Component {...pageProps} />
      <NotificationContainer />
      <Script src="https://unpkg.com/ionicons@5.2.3/dist/ionicons.js" />
    </>
  );
}

export default App;
