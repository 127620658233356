import { Store as PullStateStore, registerInDevtools } from 'pullstate';
import { lists, homeItems, notifications } from '../../mock';

const defaultState = {
  safeAreaTop: 0,
  safeAreaBottom: 0,
  menuOpen: false,
  notificationsOpen: false,
  user: {},
  token: null,
  messages: {
    error: [],
    info: [],
    success: [],
  },
  currentPage: null,
  homeItems,
  lists,
  notifications,
  settings: {
    enableNotifications: true,
  },
};
const Store = new PullStateStore({ ...defaultState });

registerInDevtools({ Store });

export default Store;
